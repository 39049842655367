import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { useEffect, useState } from "react";

const Header = ()=>{
    const mobile = window.innerWidth<=768?true:false;
    const [menuOpened, setMenuOpened]=useState(false)
    return (
        <div className="header">
            <img src={Logo} alt="" className='logo'/>

            {(menuOpened===false && mobile===true)?(
                <div><img src={Bars}  style={{width:'1.5rem',height:'1.5rem',
            backgroundColor:'var(--appColor)',padding:'0.5rem',borderRadius:'5px'}} alt="" onClick={()=>setMenuOpened(true)}/></div>
            ):<ul className='header-manu'>
            <li onClick={()=>setMenuOpened(false)}>Home</li>
            <li onClick={()=>setMenuOpened(false)}>Programs</li>
            <li onClick={()=>setMenuOpened(false)}>Why us</li>
            <li onClick={()=>setMenuOpened(false)}>Plans</li>
            <li onClick={()=>setMenuOpened(false)}>Testimonials</li>
        </ul>}

            
        </div>

    )
}


export default Header
