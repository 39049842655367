import React from 'react'
import './Join.css'
import { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser'
const Join = ()=>{
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_fhoyw5v', 'template_01xc4io', form.current, 'DPfumPPoiHCXhFwrt')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    return(
        <div className="Join" id="join-us">
            <div className='left-j'>
                <hr />
                <div><span className='stroke-text'>READY TO</span><span>LEVEL UP</span></div>
                <div><span className='stroke-text'>YOUR BODY</span><span>WITH US?</span></div>

            </div>
            <div className="right-j">
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user_email' placeholder='Enter your Email address' />
                    <button className='btn btn-j'>Join Now</button>
                </form>

            </div>
        </div>
    )
}

export default Join
